<messages>["./Domain"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <div>
    <base-layout mw3>
      <v-col cols="12">
        <!-- (partial) success message -->
        <v-alert
          v-model="isSuccessAlertVisible"
          type="success"
          dismissible>
          <i18n
            v-if="successfulNames.length === 1"
            path="create.success.single">
            <template #link>
              <router-link
                class="success-alert-link"
                :to="{ name: `${createdObjectTypes[0]}.view`,
                       params: { name: successfulNames[0] } }"
                v-text="successfulNames[0]"/>
            </template>
          </i18n>
          <i18n
            v-else
            path="create.success.multiple">
            <template #links>
              <span>
                <span
                  v-for="(name, idx) in successfulNames"
                  :key="name">
                  <router-link
                    class="success-alert-link"
                    :to="{ name: `${createdObjectTypes[idx]}.view`, params: { name } }"
                    v-text="name"/><span
                      v-if="idx < successfulNames.length - 1"
                      v-text="`, `"/>
                </span>
              </span>
            </template>
          </i18n>
        </v-alert>

        <!-- failure message -->
        <v-alert
          type="error"
          dismissible
          :value="isSuccessAlertVisible && failedNames.length > 0">
          <span
            v-text="$tc (
              'create.failure', failedNames.length,
              {domains: failedNames.join (', ')}
            )"/>
        </v-alert>
      </v-col>
    </base-layout>

    <base-layout mw0>
      <v-col
        v-if="showDomainInput"
        cols="12">
        <form
          novalidate
          @submit.prevent="onEnterDomainName">
          <v-card>
            <v-card-title>
              <span
                v-t="'create.nameInput.headline'"
                class="text-h5"/>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model.trim="domainName"
                    autofocus
                    spellcheck="false"
                    :loading="isLoadingRegistry || !showDomainInput"
                    :error-messages="domainNameValidationErrors"
                    @input="registryLoadingFailed = false"/>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn
                v-t="'create.nameInput.selectRegistry'"
                @click="onManualMode"/>
              <v-btn
                v-t="'general.button.ok'"
                color="primary"
                type="submit"/>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
    </base-layout>

    <v-alert
      id="invalidDataAlert"
      type="error"
      :value="isInvalidDataAlertVisible">
      {{
        formErrors
          ? $t ('general.invalid.form')
          : $t ('create.invalid.alert')
      }}
    </v-alert>

    <domain-create-update
      v-show="!showDomainInput"
      :is-create="true"
      :registry-id="registryId"
      :domain="domainName"
      @success="onSuccess"
      @failure="onFailure"
      @dataValidityChanged="onDataValidityChanged"
      @cancel="back"
      @startOver="onStartOver"/>
  </div>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {toASCII as punycodeToASCII} from 'punycode/punycode.es6'
  import {mapActions, mapGetters, mapMutations} from 'vuex'
  import goTo from 'vuetify/lib/services/goto'

  import {required} from 'vuelidate/lib/validators'

  import {idnDomain} from '@/app/validators'

  import validationMixins from '@/app/core/mixins/ValidationHelper'

  import DomainCreateUpdate from './DomainCreateUpdate'
  import BaseLayout from '@/app/core/components/BaseLayout'

  export default {
    name: 'DomainCreate',

    components: {
      BaseLayout,
      DomainCreateUpdate
    },

    mixins: [validationMixins],

    data () {
      return {
        showDomainInput: true,
        domainName: '',
        registryId: '',
        isLoadingRegistry: false,
        showConfirmationDialog: false,
        registryLoadingFailed: false,
        successfulNames: [],
        createdObjectTypes: [],
        failedNames: [],
        isSuccessAlertVisible: false,
        isInvalidDataAlertVisible: false,
        formErrors: false
      }
    },

    validations: {
      punycodeDomainName: {
        required,
        domain: idnDomain
      }
    },

    computed: {

      ...mapGetters ('create', ['isDomainCreationInProgress']),

      punycodeDomainName () {
        return punycodeToASCII (this.domainName)
      },

      domainNameValidationErrors () {
        let valErr = this.validationErrors ('punycodeDomainName', {
          domain: this.$t ('general.invalid.domainName'),
          required: this.$t ('general.required')
        })

        if (valErr.length === 0 && this.registryLoadingFailed) {
          valErr = this.$t ('create.nameInput.invalidEnding')
        }

        return valErr
      }
    },

    watch: {
      showDomainInput (newValue) {
        this.setDomainCreationInProgress (!newValue)
      }
    },

    created () {
      this.showDomainInput = !this.isDomainCreationInProgress
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      ...mapMutations ('create', ['setDomainCreationInProgress']),

      ...mapMutations ('notification', ['addEvent']),

      /**
       * Try to load the registry responsible for the entered domain name.
       * On success, the (pre-populated) domain create form is shown; otherwise,
       * an error message is displayed.
       */
      onEnterDomainName () {
        this.$v.$touch ()

        if (!this.$v.$error) {
          this.isLoadingRegistry = true

          this.fetchData ({
            op: 'registry/forDomain',
            params: {
              name: this.domainName
            },
            cb: data => {
              if (data.registryId === null) {
                this.registryLoadingFailed = true
              } else {
                this.registryId = data.registryId
                this.showDomainInput = false
              }
            },
            cbFinal: () => {
              this.isLoadingRegistry = false
            }
          })
        }
      },

      /**
       * Skip the domain name dialog and show the registry selection.
       */
      onManualMode () {
        this.domainName = ''
        this.registryId = ''
        this.showDomainInput = false
      },

      /**
       * Reset the validation and show the empty domain name input field.
       */
      onStartOver () {
        this.domainName = ''
        this.registryId = ''
        this.$v.$reset ()
        this.showDomainInput = true
        this.isInvalidDataAlertVisible = false
      },

      onSuccess ({successfulNames, failedNames, createdObjectTypes}) {
        this.successfulNames = successfulNames
        this.failedNames = failedNames
        this.createdObjectTypes = createdObjectTypes
        this.isSuccessAlertVisible = true

        this.addEvent ({
          type: 'success',
          message: this.$t (successfulNames.length === 1
            ? 'create.success.single'
            : 'create.success.multiple', {link: successfulNames[0], links: successfulNames.reduce ((acc, i) => acc === '' ? i : acc + ', ' + i)}),
          objects: successfulNames.map ((name, idx) => ({
            name,
            link:
              {
                name: `${this.createdObjectTypes[idx]}.view`,
                params: {name}
              }
          }))
        })

        if (this.failedNames.length === 0) {
          this.onStartOver ()
        }
      },

      onFailure () {
        this.isSuccessAlertVisible = false
        this.formErrors = false
        this.isInvalidDataAlertVisible = true
        this.scrollToAlert ()
      },

      onDataValidityChanged (isValid) {
        console.warn ('onDataValidityChanged', isValid)
        this.formErrors = !isValid
        this.isInvalidDataAlertVisible = !isValid
        if (this.isInvalidDataAlertVisible) this.scrollToAlert ()
      },

      back () {
        this.$router.back ()
      },

      scrollToAlert () {
        goTo ('#invalidDataAlert')
      }
    }
  }
</script>

<!--
================================================================================
  Styling
================================================================================
-->

<style scoped>
.success-alert-link {
  text-decoration: underline;
  color: #d5ffff
}
</style>
